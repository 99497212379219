@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your additional styles go here */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.patternbody {
  overflow-x: hidden;
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (min-width: 1000px) {
  .patternbody:before {
    content: "";
    position: absolute;
    background: url(./images/mainbg1.png) no-repeat;
    width: 115px;
    height: 40%;
    background-position: 0;
    left: 0;
    top: 0;
    background-size: contain;
  }

  .patternbody:after {
    content: "";
    position: absolute;
    background: url(./images/mainbg2.png) no-repeat;
    width: 488px;
    height: 13%;
    background-position: 100%;
    right: 0;
    top: 0;
    background-size: contain;
  }
}

@media screen and (max-width: 1000px) {
  .patternbody:before {
    content: "";
    position: absolute;
    background: url(./images/mainbg1.png) no-repeat;
    width: 115px;
    height: 35%;
    background-position: 0;
    left: 0;
    top: 0;
    background-size: contain;
  }

  .patternbody:after {
    content: "";
    position: absolute;
    background: url(./images/mainbg2.png) no-repeat;
    width: 488px;
    height: 10%;
    background-position: 100%;
    right: 0;
    top: 0;
    background-size: contain;
  }
}

@media screen and (max-width: 780px) {
  .patternbody:before {
    content: "";
    position: absolute;
    background: url(./images/mainbg1.png) no-repeat;
    width: 90px;
    height: 22%;
    background-position: 0;
    left: 0;
    top: 0;
    background-size: contain;
  }

  .patternbody:after {
    content: "";
    position: absolute;
    background: url(./images/mainbg2.png) no-repeat;
    width: 488px;
    height: 6%;
    background-position: 100%;
    right: 0;
    top: 0;
    background-size: contain;
  }
}

@media screen and (max-width: 480px) {
  .patternbody:before {
    content: "";
    position: absolute;
    background: url(./images/mainbg1.png) no-repeat;
    width: 75px;
    height: 16%;
    background-position: 0;
    left: 0;
    top: 0;
    background-size: contain;
  }

  .patternbody:after {
    content: "";
    position: absolute;
    background: url(./images/mainbg2.png) no-repeat;
    width: 250px;
    height: 3%;
    background-position: 100%;
    right: 0;
    top: 0;
    background-size: contain;
  }
}

code {
  font-family: 'Poppins', sans-serif;
}

/* header  */

.hrpricebg {
  background-image: url(./images/headerprice.png);
}


@media screen and (min-width: 768px) and (max-width: 850px) {
  .headerul {
    gap: 5px;
    font-size: 11px;
  }
}


@media screen and (min-width: 851px) and (max-width: 950px) {
  .headerul {
    gap: 10px;
    font-size: 12px;
  }
}


/* head section  */

.headcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px
}

.hcbg1 {
  background-blend-mode: difference;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  width: 900px;
  height: 580px;
}

@media screen and (max-width: 1000px) {
  .hcbg1 {
    width: 100vw;
    height: 500px;
  }
}

@media screen and (max-width: 770px) {
  .headcontainer {
    padding-top: 10%;
  }
}

@media screen and (max-width: 640px) {
  .headtext {
    font-size: 11vw;
    line-height: 13vw;
  }

  .headsubtext {
    margin-top: 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 460px) {
  .headcontainer {
    padding-top: 6%;
  }

  .headtext {
    font-size: 12vw;
    line-height: 13vw;
  }

  .hcbg1 {
    height: 450px;
  }

  .headsubtext {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .headcontainer {
    padding-top: 4%;
  }

  .headtext {
    font-size: 12vw;
    line-height: 13vw;
  }
}

@media screen and (max-width: 380px) {
  .headsubtext {
    font-size: 12px;
  }
}

.trustbg {
  background-image: url(./images/Sections/HeadSection/trust.png);
}

.rightbg {
  background-image: url(./images/Sections/HeadSection/right.png);
}

/* stay active section  */
.buyegoldbg {
  background-image: url(./images/Sections/StayActive/buyegold.png);
}

.stakeegoldbg {
  background-image: url(./images/Sections/StayActive/stakeegold.png);
}

.mineegoldbg {
  background-image: url(./images/Sections/StayActive/mineegold.png);
}

.bgtop {
  top: -30px;
}

.stacbg {
  background-image: url(./images/Sections/StayActive/stacbg.png);
  background-size: contain;
  background-repeat: no-repeat;
  top: 0px;
}

.stacbtn {
  background-image: url(./images/Sections/StayActive/stacbtn.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1000px) {
  .sthead {
    margin-top: 6%;
  }

  .stflboxes {
    flex-direction: column;
  }

  .stacbg {
    top: unset;
  }

  .stflbox {
    margin-top: 70px;
  }
}

/* egold supply */

.egsupplybg {
  background-image: url(./images/Sections/EgoldSupply/bg.png);
}

.dotlines {
  background-image: url(./images/Sections/EgoldSupply/dotlines.png);
}

.egsburn {
  background-image: url(./images/Sections/EgoldSupply/burn.png);
}

.egsopening {
  background-image: url(./images/Sections/EgoldSupply/opening.png);
}

.egslast24 {
  background-image: url(./images/Sections/EgoldSupply/last24.png);
}

.egsmax {
  background-image: url(./images/Sections/EgoldSupply/max.png);
}

@media screen and (max-width: 780px) {
  .egxflexboxes {
    flex-direction: column;
  }

  .egmainbox {
    display: flex;
    flex-direction: column;
    gap: 140px;
    height: 720px;
  }
}


@media screen and (max-width: 380px) {
  .egsupplybg {
    width: 100vw;
    background-size: contain;
  }

  .dotlines {
    right: 0;
    left: unset;
  }

  .egindibox {
    width: 100vw;
  }
}

/* timeleft */


.tltext {
  background: rgb(36, 36, 36);
  background: linear-gradient(90deg, rgba(36, 36, 36, 1) 0%, rgba(78, 78, 78, 1) 10%, rgba(101, 101, 101, 1) 32%, rgba(19, 19, 19, 1) 64%, rgba(78, 78, 78, 1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.timeleftbg {
  background-image: url(./images/Sections/TimeLeft/timeleftbg.png);
  background-color: white;
  background-blend-mode: difference;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.tlhourglass {
  background-image: url(./images/Sections/TimeLeft/hourglass.png);
}

@media screen and (max-width: 780px) {
  .timeleftbg {
    background-image: url(./images/Sections/StayActive/stacbg.png);
    background-color: white;
    background-blend-mode:normal;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 350px;
    width: 350px;
    margin: auto;
  }

  .tlbx1 {
    width: 100%;
    left: unset;
    top: 210px;
  }

  .tlbx1 div {
    margin: auto;
  }

  .tlbx2 {
    justify-content: center;
    width: 100%;
    right: unset;
  }
}

/* keyfeatures */
.kfmining {
  background-image: url(./images/Sections/KeyFeatures/mining.png);
}

.kfburn {
  background-image: url(./images/Sections/KeyFeatures/burn.png);
}

.kfblock {
  background-image: url(./images/Sections/KeyFeatures/block.png);
}

.kfdeflation {
  background-image: url(./images/Sections/KeyFeatures/deflation.png);
}

.keysubtext {
  background: linear-gradient(180deg, rgba(133, 108, 57, 1) 0%, rgba(187, 162, 111, 1) 20%, rgba(216, 191, 140, 1) 45%, rgba(157, 132, 81, 1) 70%, rgba(119, 94, 43, 1) 86%, rgba(134, 109, 58, 1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media screen and (max-width: 780px) {
  .kfflexbx {
    flex-direction: column;
    gap: 70px;
    align-items: center;
  }

  .kfmain {
    margin-top: 160px;
  }
}

.kf2display {
  display: block;
}

.kfdisplay {
  display: none;
}


@media screen and (max-width: 840px) {

  .kf2display {
    display: none;
  }
  .kfdisplay {
    display: block;
  }
  
}

/* contracts */

.metamaskbg {
  background-image: url(./images/Sections/Contracts/metamask.png);
}

.rightarrowbg {
  background-image: url(./images/Sections/Contracts/rightarrow.png);
}

.scontractbg {
  background-image: url(./images/Sections/Contracts/scontractsbg.png);
}

.scbox {
  background-image: url(./images/Sections/Contracts/scbox.png);
}

.scmain {
  height: 500px;
}

.scontractbg {
  background-size: contain;
  height: 500px;
}

@media screen and (min-width: 550px) and (max-width:1000px) {
  .scontractbg {
    height: 480px;
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 550px) {
  .contractflbox {
    flex-direction: column;
    height: 100%;
    align-items: center;
    gap: 10px;
    justify-content: normal;
    height: 750px;

  }

  .scontractbg {
    height: 800px;
    background-position: center;
    background-size: cover;
  }

  .indiscbox {
    gap: 160px;
  }

  .scmain {
    padding: 10px;
    height: 800px;
  }
}

/* charts */

.charttext {
  background: rgb(221, 130, 1);
  background: linear-gradient(90deg, rgba(221, 130, 1, 1) 0%, rgba(255, 174, 9, 1) 20%, rgba(222, 131, 2, 1) 45%, rgba(244, 161, 7, 1) 70%, rgba(222, 131, 2, 1) 85%, rgba(252, 172, 8, 1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.chbtnbg {
  background-image: url(./images/Sections/Charts/chartbuttonbg.png);
}

@media screen and (max-width: 780px) {
  .chartflbox {
    flex-direction: column;
    height: 1200px;
    gap: 80px;
  }
}

/* tech partners */
.tpflbx {
  margin-bottom: 20px;
}

.tpflbx img {
  max-width: 100%;
  height: auto;
  max-height: 55px;
  margin: auto;
}

@media screen and (min-width: 780px) {
  .tpflbx img {
    max-height: 65px;
  }
}

/* footer  */

.xbg {
  background-image: url(./images/Footer/twitter.png);
}

.tgbg {
  background-image: url(./images/Footer/telegram.png);
}

.egoldbg {
  background-image: url(./images/headerlogo.png);
}

.footerdivbg1 {
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.footerdivbg2 {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(217, 217, 217, 1) 100%);
}

.footerbg1fbx {
  padding: 3% 0px;
}

.footerbg2fbx {
  align-items: center;
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .footerbg1fbx {
    padding: 3% 3%;
  }

  .footerdivbg2 {
    padding: 1% 3%;
  }
}

@media screen and (max-width: 1000px) {
  .fffbox {
    flex-direction: column;
    align-items: center;
  }

  .fmenu {
    text-align: center;
  }

  .footlog {
    padding: 42px;
  }

  .footerdivbg2 {
    padding: 1% 3%;
  }

  .footerbg1fbx {
    padding-bottom: 70px;
  }
}


@media screen and (max-width: 780px) {
  .footerbg2fbx {
    flex-direction: column;
    gap: 18px;
    height: 100px;
  }
}

/* calculator */

.mainsection {
  max-width: 600px;
  margin: auto;
}

.lightgrey-bg {
  background-color: #eeeeee;
}

.secpadding {
  padding: 15px;
}

.calcwrap {
  background: #ffff;
  border-radius: 8px;
  padding: 20px;
}

.calcwrap .form-label {
  margin-bottom: 0.5rem;
}

.calcwrap label {
  display: inline-block;
}

.calcwrap .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.calcwrap .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.calcwrap .input-group>.form-control,
.calcwrap .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.calcwrap .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.calcwrap .form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.yeiddiv {
  background: #f5f5f5;
  justify-content: space-between;
  display: flex;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

/* common elements */
.heads {
  padding-bottom: 20px;
}

@media screen and (max-width: 520px) {
  .heads {
    font-size: 35px;
  }
}


.loading {
  height: 0;
  width: 0;
  padding: 15px !important;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  border-bottom-width: 6px !important;
  background-color: unset !important;
  /* left, top and position just for the demo! */
  margin: auto;
}

@keyframes rotate {

  /* 100% keyframe for  clockwise. 
         use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {

  /* 100% keyframe for  clockwise. 
         use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}


.fullchbox {
  box-shadow: rgba(164, 160, 160, 0.41) 0px 0px 7px;
}

.chtable {
  width: 70%;
  margin: 0 auto;
}

.chbxhdiv {
  height: 450px;
}

.chartbx {
  height: 67%;
}

@media screen and (min-width: 1500px) and (max-width: 1950px) {
  .chtable {
    width: 90%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1400px) {
  .chtable {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1120px) {
  .chtable {
    width: 100%;
  }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
  .chtable {
    width: 85%;
  }

  .prodtable {
    width: 82vw;
    margin: auto;
  }
}

@media screen and (max-width: 550px) {
  .chtable {
    width: 100%;
  }

  .chbxhdiv {
    margin-bottom: 15px;
  }

  .prodtable {
    width: 85vw;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .mobnone {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .chbxhdiv {
    height: 400px;
  }

  .tableitemx {
    font-size: 13px;
    padding: 15px;
  }
}

@media screen and (max-width: 400px) {
  .chbxhdiv {
    height: 350px;
  }

  .tableitemx {
    font-size: 12px;
    padding: 20px;
  }
}

@media screen and (max-width: 370px) {
  .chbxhdiv {
    height: 300px;
  }
}

@media screen and (max-width: 365px) {
  .tableitemx {
    font-size: 12px;
    padding: 10px;
  }
}